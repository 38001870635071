import React from 'react';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import {LoadAndSaveSignupDefaultCode} from '../../components/Modules/DefaultCode';
import LoadingProgress from '../../components/Modules/LoadingProgress';

const withAuthentication = Component => {
    class WithAuthentication extends React.Component {
        _isMounted = false;
        constructor(props) {
            super(props);
            var storedAuthUser = JSON.parse(window?.localStorage?.getItem('authUser'));
            //console.log('authuser local on startop', storedAuthUser, this.props, window.location.hash);
            var location = window.location.hash || "";
            var skipAuthentication = false;
            if(location.includes("#/creatorsignup") || location.includes("#/signin") || location.includes("#/signup") || location.includes("#/creatorsignup")){
                skipAuthentication = true;
            }
            var syncUid = '';
            if(storedAuthUser !== null && storedAuthUser !== undefined && storedAuthUser.uid !== undefined){
                syncUid = storedAuthUser.uid;
            }
            this.state = {
                authUser: storedAuthUser,
                syncActive: false,
                syncUid: syncUid,
                loading: (skipAuthentication === true ? false : true),
                skipAuthentication: skipAuthentication
            };
        }

        componentDidMount() {
            this._isMounted = true;
            //console.log('AUTH MOUNTED')
            this.listener = this.props.firebase.onAuthUserListener((authUser) => {
                    if(!(this.state.context?.uid?.length > 1) && this._isMounted && this.state.skipAuthentication!==true){
                        //this.setState({ loading: true });
                        this.setState({ loading: false });
                        //console.log('uid missing', this.state.context)
                    }
                    this.startUserSync(authUser);
                    //console.log('Auth onAuth Mount', authUser,  this.state.syncUid)
                    if(this.state.syncActive!==true || (this.state.syncUid !== '' && this.state.syncUid !== authUser.uid )){
                        //localStorage?.setItem('authUser', JSON.stringify(authUser));
                        if(this._isMounted){
                            this.setState({ authUser, syncUid: authUser.uid});
                        }
                    }
                }, (status) => {
                    //this.props.firebase.stopUserUpdateListener();
                    if(window.navigator.onLine ){
                        //console.log('Autheentication cancel usere info', status);
                        this.props.firebase.stopUserUpdateListener();
                        window?.localStorage?.removeItem('authUser');
                        window?.sessionStorage?.removeItem('defaultSet');
                        this.stopListener();
                    } 
                },
            );
        }

        startUserSync = (authUser) => {
            //console.log('SYNNC REQUEST')
            var userIdToSync = authUser.uid;
            if(this.state.syncUid !== "" && this.state.syncUid !== authUser.uid ){
                console.log('SYNNC REVMOED DIFFERENT USER')
                this.stopListener();
            }
            if(userIdToSync?.length > 2 || this.state.syncActive !== true){
                //console.log('NEW authlustneer', userIdToSync)
                if(this._isMounted){
                    this.setState({ syncActive: true, loading: (this.state.skipAuthentication === true ? false : true) });
                    //console.log('NEW authlustneer', userIdToSync)
                }
                ///////// HARDCORE OVERWRITE
                /////////  userIdToSync = "sddsdfds";
                //console.log('HARDCORE OVERWRITE', userIdToSync);
                try{
                    this.props.firebase.authUserListener = this.props.firebase.user(userIdToSync)
                    .onSnapshot(
                        (snapshot) => {
                            var dbUser = snapshot.data();
                            //var storedAuthUser = JSON.parse(window?.localStorage?.getItem('authUser'));
                            //console.log('this.auth.currentUser', dbUser, userIdToSync)
                            if(dbUser === undefined){
                                this.setState({loading: true});
                                console.log('empty user yet, wait and do again');
                                //console.log('this.auth.currentUser', authUser, this.context, this.props.firebase?.auth?.currentUser)
                            }
                            else if(snapshot.id === userIdToSync){
                                //console.log('auth ready ID')
                                //console.log('SNAPSHOT UPDTE', dbUser, snapshot.id, this.props.firebase.auth, this.props.firebase.authUserListener )
                                if(this._isMounted){
                                    this.setState({ syncUid: snapshot.id});
                                    if(this.state.context?.uid?.length > 1 && this.state.loading === true){
                                        //console.log('SNAPSHOT context:', authUserUpdate)
                                        this.setState({loading: false});
                                    }
                                }
                                if ( dbUser === undefined) {
                                    //dbUser = myInstallationOfflineDataSet
                                }
                                if (!("roles" in dbUser) || dbUser?.roles?.length === 0) {
                                    dbUser.roles = ['default'];
                                    //console.log(' withAuth add default role')
                                }    
                                var authUserUpdate = {
                                    uid: userIdToSync,
                                    email: authUser.email,
                                    emailVerified: authUser.emailVerified,
                                    providerData: authUser.providerData,
                                    onSnapshotListener: true,
                                    ...dbUser,
                                };
                                //console.log('set authuser')
                                //window?.localStorage?.setItem('authUser', JSON.stringify(authUserUpdate));
                                window?.localStorage?.setItem('authUser', 
                                    JSON.stringify({
                                        uid: userIdToSync, 
                                        timezone: (authUserUpdate?.timezone || 'UTC'), 
                                        roles: (authUserUpdate?.roles || []), 
                                        features: (authUserUpdate?.features || {}),
                                        programs: (authUserUpdate?.programs || {}) 
                                    }));
                                if(this._isMounted){
                                    //console.log('SNAPSHOT context:', authUserUpdate)
                                    this.setState({ context: authUserUpdate, authUser: authUserUpdate}, () => {
                                        if(this._isMounted){
                                            this.setState({loading: false})
                                        }
                                    });
                                }
                                //// if page defaultSet is already a DT, then cannot overwrite with local
                                if(authUserUpdate?.programCode?.includes("#") && !this.props.defaultSet?.programCode?.includes("#")){
                                    var storedDefaultSet = window?.localStorage?.getItem('defaultSet');
                                    var storedSessionSet = window?.sessionStorage?.getItem('defaultSet');
                                    //console.log('AUTH storedDefaultSet auth', authUserUpdate.programCode, this.props, storedDefaultSet, storedSessionSet)
                                    if(storedSessionSet !== false && (storedDefaultSet === null || storedDefaultSet === undefined)){
                                        //console.log("authUserUpdate", authUserUpdate?.programCode);
                                        LoadAndSaveSignupDefaultCode(this, {}, authUserUpdate.programCode, 'sessionStorage');
                                    }
                                }
                            }
                            else{
                                console.log('SNAPSHOT WITH NEW USER')
                                this.stopListener();
                            }
                        }, 
                        (error) => {
                            console.log('auth  error', authUser.uid,  error)
                            if(window.navigator.onLine ){
                                this.stopListener();
                            } 
                        }
                    )
                }
                catch(error){
                    console.log('fb catch  error', authUser.uid,  error)
                    if(window.navigator.onLine ){
                        this.stopListener();
                    } 
                }
            }
        }

        componentWillUnmount() {
            this._isMounted = false;
            //console.log('UNMOPUNT')
            this.stopListener();
            this.listener();
            this.stopListener();
        }

        stopListener = () => {
            if(this._isMounted){
                //console.log('Auth stoplistener Set Eempty')
                this.setState({ authUser: undefined, context: undefined, loading: false, syncUid: ""});
            }
            if(this.props.firebase.authUserListener !== undefined && this.props.firebase.authUserListener!== null && typeof this.props.firebase.authUserListener === 'function'){
                if(this._isMounted){
                    this.setState({ syncActive: false });
                }
                this.props.firebase.stopUserUpdateListener();
                console.log('STOPPED SYNC')
            }
        }

        render() {
            //console.log('Auth', this.props.firebase, this.state, Component)
            return (
                <AuthUserContext.Provider value={this.state.context || this.state.authUser}>
                    {true
                    ?
                        <div style={{display: this.state.loading !== true ? "block" : 'none'}}>
                            <Component {...this.props} contextLoaded={this.state.context?.uid?.length > 1 ? true : false} themeStyle={this.state?.context?.features?.darkmode ? 'darkmode' : 'default'} />
                        </div>
                    :   <span style={{marginTop: 50}}>Not Connected</span>
                    }
                    {(this.state.loading === true)
                        &&
                            <div style={{paddingTop: '25vH', paddingBottom:20}}>
                                <div style={{
                                    borderRadius: 20,
                                    backgroundImage: 'linear-gradient(230deg, #FFFFFF77 0%, /* Transparent for top third */ #FFFFFF98 66%, /* Semi-opaque color for middle third */ #FFFFFF99 100% /* Solid color for bottom third */)',
                                    padding: 20,
                                    minHeight: "50vh",
                                    marginBottom: 'calc( 20px + env(safe-area-inset-bottom))',
                                    paddingTop: 20,
                                    paddingBottom: 20,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    width: "calc( 100vw - 20px)",
                                    maxWidth: 600,
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                }}>
                                    <LoadingProgress
                                        allowRefresh = {true}
                                        theme = {this.props.colorTheme}
                                        defaultSet={this.props.defaultSet}
                                        stopLoading={!(this.state.syncUid?.length > 1)}
                                        type={'LoadingPageAnimated'} 
                                        loadingText={window.navigator.onLine ? (this.state.syncUid?.length > 1) ? "I'm verifying our secure link" : "Get the secure connection" : "I'm tryng to connect"} 
                                    />
                                </div>
                            </div>
                    }
                </AuthUserContext.Provider>
            );
        }
    }

    return withFirebase(WithAuthentication);
};

export default withAuthentication;
