import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';
import { withStyles } from '@material-ui/core/styles';
//import {GetTimeZone} from '../Modules/LoadUserTimezone';

//import { Link } from 'react-router-dom';
//import {IsMobileDevice} from '../Modules/IsMobileDevice';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import SendIcon from '@material-ui/icons/Send';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';

import FormControl from '@material-ui/core/FormControl';
import { SelectOption, SelectWrapper } from '../Modules/SelectOption';

import Card from '@material-ui/core/Card';
import {checkInTimeRange} from '../Routine/SetAgentStatus';
import {SetAgentDialogue, minSessionTimeStamp} from '../Routine/SetAgentStatus';

const styles = theme => ({
  error: {
    color: theme.palette.themeRed,
    marginTop: 20,
  },
  grid: {
    margin: 5,
    maxWidth: theme.maxWidthDialog,
  },
  loading: {
    marginTop: 50,
    textAlign: 'center',
    marginBottom: 50,
  },
  textInput: {
    backgroundColor: 'white',
    borderRadius: 5,
    color: 'darkgrey',
    width: '95%',
    maxWidth: 400,
    fontSize: '16px',
  },
  
});


class DiaryManual extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        var dateNow = new Date();
        var dialogueType = 'priming';
        if(this.context !== undefined && this.context.reflectionFrom !== undefined){
            var reflectionFrom = this.context.reflectionFrom;
            var primingFrom  = this.context.primingFrom;
            if( checkInTimeRange(reflectionFrom, primingFrom)) {
                dialogueType = 'reflection';
                //console.log('reflection time')
            } 
        }
        else if (checkInTimeRange("15:00", "04:00")) {
            dialogueType = 'reflection';
        } 
        this.state = {
            loading: false,
            open: false,
            dialogueType: dialogueType,
            utteranceText: "Reflection",
            utteranceClass: "default",
            measurement: 3,
            date: dateNow
        };
    }

    render(){
        const { classes, theme } = this.props;
        const { botUtterSets, loading } = this.state;


        const dateNow = new Date(); // Creating a new date object with the current date and time
        const year = dateNow.getFullYear(); // Getting current year from the created Date object
        const monthWithOffset = dateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
        const month = // Setting current Month number from current Date object
        monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 to adjust for date input.
            ? `0${monthWithOffset}`
            : monthWithOffset;
        const date =
        dateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
            ? `0${dateNow.getUTCDate()}`
            : dateNow.getUTCDate();

        const materialDateInput = `${year}-${month}-${date}`;
        //console.log(this.state)
        if (loading === false){
        return (
            <Card style={theme.CardContentShadow}>
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                    className={classes.grid}
                    >
                    <Grid item xs={12}>
                        <p>
                        My Reflection Comments
                        </p>
                    </Grid>
                    {(this.props.fixedDate === undefined ||
                        this.props.fixedDate === false )
                    &&
                        <Grid item xs={12} style={{ minHeight: 20 }}>
                            <TextField
                                id="diaryDate"
                                //label="Date"
                                type="date"
                                variant="outlined"
                                style={ {fontSize: '16px'} }
                                defaultValue={materialDateInput}
                                onChange={(event) => this.setState({ date: event.target.value }) }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    }
                    {false && (this.props.fixedDialogueType === undefined ||
                        (this.props.fixedDialogueType !== 'reflection' &&
                        this.props.fixedDialogueType !== 'priming' ))
                    &&
                        <Grid item xs={12} style={{ minHeight: 20 }}>
                            <FormControl >
                                <InputLabel id="agent_select">Type</InputLabel>
                                <SelectWrapper
                                    //labelId="agent_select"
                                    id="dialogType"
                                    value={this.state.dialogueType || ( dateNow.getHours() >= 15 ? 'reflection' : 'priming')}
                                    onChange={(event) => this.setState({ dialogueType: event.target.value }) }
                                    
                                >
                                    <SelectOption value={'priming'}>Coaching Chat</SelectOption>
                                    <SelectOption value={'reflection'}>Reflective Chat</SelectOption>
                                </SelectWrapper>
                            </FormControl>
                        </Grid>
                    }
                    <Grid item xs={12} style={{ minHeight: 20 }}>
                        <FormControl >
                            <InputLabel id="Mood_select">Mood/Outcome</InputLabel>
                            <SelectWrapper
                                //labelId="Mood_select"
                                id="measurement"
                                value={this.state.measurement || 3}
                                onChange={(event) => this.setState({ measurement: event.target.value }) }
                                
                            >
                                <SelectOption value={1}>Not good</SelectOption>
                                <SelectOption value={2}>So so</SelectOption>
                                <SelectOption value={3}>Okay</SelectOption>
                                <SelectOption value={4}>Good</SelectOption>
                                <SelectOption value={5}>Very good</SelectOption>
                            </SelectWrapper>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} style={{ minHeight: 20 }}>
                        <FormControl>
                            <SelectWrapper 
                                native 
                                value={this.state.utteranceClass || "default"}
                                input={<Input id="grouped-native-select" />}
                                style={ {fontSize: '16px'} }
                                onChange={(event) => this.setState({ utteranceClass: event.target.value, 
                                    utteranceText: event.target.options[event.target.selectedIndex].text}) }
                                //onClick={(event) => console.log( event.target.options[event.target.selectedIndex].text)}
                                
                            >
                                {
                                botUtterSets !== undefined && Object.keys(botUtterSets).length > 0
                                    ?
                                        Object.keys(botUtterSets).map(key  => (
                                            botUtterSets[key].map(utter => (
                                                <option value={utter.value} key={utter.value}>{utter.text}</option>
                                            ))
                                            /* 
                                            <optgroup label={key} key={key}>
                                            {botUtterSets[key].map(utter => (
                                                <option value={utter.value} key={utter.value}>{utter.text}</option>
                                            ))}
                                            </optgroup>
                                            */
                                        ))
                                    :   <option value={'default'}>Mindset</option>
                                }
                            </SelectWrapper>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} style={{ minHeight: 20, marginRight: 10 }}>
                        <TextField
                            variant="outlined"
                            //autoFocus
                            type="text"
                            onChange={(event) => this.setState({ message: event.target.value }) }
                            placeholder="My comment"
                            rows={2}
                            rowsMax={4}
                            value={this.state.message}
                            multiline
                            className={classes.textInput}
                            InputProps={{
                                style:{fontSize: '16px'},
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            edge="end"
                                            aria-label="Send message"
                                            onClick={() => this.saveMessage()}
                                        >
                                            <SendIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ), 
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <p className={classes.error}>
                            {this.state.error} &nbsp;
                        </p>
                    </Grid>
                </Grid>
            </Card>
        )
    }
    else{
        return(<div></div>)
    }
    }

    componentDidMount() {
        this._isMounted = true;
        //console.log('m context',this.context);
        this.setState({ loading: true });
        this.BotUtterDescription();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    BotUtterDescription() {
        var botUtterSets = [];
        this.props.firebase
        .textBotUtter()
        .where('active', '==',true)
        .get()
        .then(snapshot => {
            if (snapshot.empty) {
                botUtterSets["Mindset"] = [{category:"Mindset",  text: "Mindset", value: "default", id:"default" }];
                return;
            }
            snapshot.forEach(doc => {
                if(doc.data().category in botUtterSets)
                    botUtterSets[doc.data().category].push({ ...doc.data(), id: doc.id });
                else
                    botUtterSets[doc.data().category] = [{ ...doc.data(), id: doc.id }];
            });
            if(this._isMounted) this.setState({ loading: false });
        });
        if(this._isMounted) this.setState({ botUtterSets});
    };

    handleOpen = () => {
        this.setState({
            open: true,
        });
    }
    handleClose = () => {
        this.setState({
            open: false,
        });
    }


    saveMessage = async () => {
            
        if(this._isMounted) this.setState({error: ""});
        var now = new Date();
        //var currentHour = now.getHours();
        var selectDate = new Date(this.state.date);
        //var selectDay = new Date(this.state.date);
        //console.log(now.getTime())
        //console.log(selectDate)
        if(this.state.message === undefined || this.state.message === "" || this.state.message.length<3)
            this.setState({error: "Please add a comment"});
        else if (selectDate > now)
            this.setState({error: "Please choose a date in the past"});
        else{
            ExtendDialogueMessage(this.props.firebase, this.context, this.state.message, this.state.utteranceText, this.state.utteranceClass, '', 'manual') 
            
            if(this._isMounted) this.setState({error: "Added comment"});
            //console.log(dialogueSet,messageBot, messageUser, messageArchive )
            
            if(this._isMounted)  this.setState({message: ""})
        }
        

    }

    getNextDayOfTheWeek(dayName, excludeToday = true, refDate = new Date()) {
        const dayOfWeek = ["sun","mon","tue","wed","thu","fri","sat"]
                        .indexOf(dayName.slice(0,3).toLowerCase());
        if (dayOfWeek < 0) return;
        refDate.setHours(0,0,0,0);
        refDate.setDate(refDate.getDate() + !!excludeToday + 
                        (dayOfWeek + 7 - refDate.getDay() - !!excludeToday) % 7);
        return refDate;
    }


}

export async function ExtendDialogueMessage(db, userDataSet, message, utteranceText='Mindset', utteranceClass='default', contentId='', source='', reflectionMode=false) {
    //console.log('I am called', reflectionMode, userDataSet)
    var dialogueIdAvailable = "";
    var dialogueCreatedAt = "";
    try{
        if(message !== undefined && message !== "" && message.length>2){
            await SetAgentDialogue(userDataSet, db, true)
            .then( (agentStatus) => {
                //console.log('status returned', agentStatus)
                if(agentStatus !== undefined && agentStatus.currentDialogueId!==undefined && agentStatus.currentDialogueId!==""){
                    dialogueIdAvailable = agentStatus.currentDialogueId;
                    dialogueCreatedAt = minSessionTimeStamp(agentStatus.reflectionInTimeRange ? 'reflection' : 'priming', userDataSet);
                    //console.log('returned diallgue', dialogueIdAvailable, dialogueCreatedAt)
                    _addMessageToSets(db, userDataSet.uid, dialogueIdAvailable, dialogueCreatedAt, message, utteranceText, utteranceClass, contentId, source, reflectionMode);
                }
            })
        }
    }
    catch (err) {
        console.log('Issue with adding message', err);
    }
}

async function _addMessageToSets (db, uid, dialogueId, selectDate, message, utteranceText='Mindset', utteranceClass='default', contentId='', source='', reflectionMode=false){
    ////////////b messages
    //console.log('add message', dialogueId)
    if(dialogueId!== undefined && dialogueId!==''){
        var randomSecond = Math.floor(Math.random() * 110) + 1  
        /////// this message is out into the flow 120 seconds before the dialogue started and each pair with a random timer
        var selectDate2 = new Date(selectDate.getTime() + 1000 * randomSecond - 120000);
        selectDate2.setDate(selectDate2.getDate()-1);
        var textA =  [utteranceText];
        if(source === 'activities'){
            textA =  ['Please tell me more about:', utteranceText];
        }
        if(utteranceClass === 'utter_ask_resources_goal'){
            textA = ['What is your intention on:', utteranceText];
        }
        var messageBot = {
            dialogueId : dialogueId,
            createdAt : db.fieldValue.serverTimestamp(),
            isCoach : true,
            text: textA,
            utteranceClass: [utteranceClass],
            userId: uid,
            messageFilter: 'extendedMessage'
        }
        if(contentId!== undefined && contentId!==""){
            messageBot['contentId'] = contentId;
        }
        await db.messages().add(messageBot);

        var selectDate3 = new Date(selectDate.getTime() + 1000 * (randomSecond + 1) -120000);
        selectDate3.setDate(selectDate3.getDate()-1);
        //console.log(selectDate3)
        var messageUser = {
            dialogueId : dialogueId,
            createdAt : db.fieldValue.serverTimestamp(),
            isCoach : false,
            text: [message],
            message: message,
            //postprocessed: true,
            userId: uid,
        }
        if(contentId!== undefined && contentId!==""){
            messageUser['contentId'] = contentId;
        }
        if(source!== undefined && source==='activities'){
            messageUser['addFollowUp'] = false;
        }

        var docRefMessage = await db.messages().add(messageUser);
        //console.log("messages", docRefMessage, messageUser)
        //console.log(docRefMessage.id)
        // c comments
        var dateNextSat = new Date(selectDate);
        dateNextSat = getNextDayOfTheWeek("sat", false, dateNextSat );
        const dayOfWeek = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"]
        var dateString = 
            selectDate.getFullYear() +"-"+
            ("0" +  (selectDate.getMonth()+1)).slice(-2) +"-"+ 
            ("0" + selectDate.getDate()).slice(-2) + " " + 
            dayOfWeek[selectDate.getDay()] + " " + 
            ("0" + selectDate.getHours()).slice(-2) + ":" + 
            ("0" + selectDate.getMinutes()).slice(-2) + ":" + 
            ("0" + selectDate.getSeconds()).slice(-2);
        //console.log("next sat", dateNextSat)
        var dateNextSatString = 
            dateNextSat.getFullYear() +"-"+
            ("0" +  (dateNextSat.getMonth()+1)).slice(-2) +"-"+ 
            ("0" + dateNextSat.getDate()).slice(-2);
        var messageArchive = {
            dialogueId : dialogueId,
            messageId : docRefMessage.id,
            createdAt : selectDate3,
            date: dateString,
            day: dayOfWeek[selectDate3.getDay()],
            message: message,
            bot_utter: utteranceClass,
            question:  utteranceText,
            questionTitle: utteranceText,
            userId: uid,
        }
        db.insightsWordsArchiveMessages(uid, dateNextSatString).set({
            messages : db.fieldValue.arrayUnion(messageArchive),
            category : 'weeklyJournal'
        }, {merge:true }); 
        //console.log(dialogueSet,messageBot, messageUser, messageArchive )
}
}

function getNextDayOfTheWeek(dayName, excludeToday = true, refDate = new Date()) {
    const dayOfWeek = ["sun","mon","tue","wed","thu","fri","sat"]
                      .indexOf(dayName.slice(0,3).toLowerCase());
    if (dayOfWeek < 0) return;
    refDate.setHours(0,0,0,0);
    refDate.setDate(refDate.getDate() + !!excludeToday + 
                    (dayOfWeek + 7 - refDate.getDay() - !!excludeToday) % 7);
    return refDate;
}

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(DiaryManual);
