import React, { Component } from "react";
import { Link , withRouter} from "react-router-dom";
import { AddAnalytics } from '../Modules/AddAnalytics';
import {LoadAndSaveSignupDefaultCode} from '../Modules/DefaultCode';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import { withFirebase } from '../Firebase';
import {CheckCoachCode} from '../Modules/URLQueries';
import DOMPurify from 'dompurify';

// style used same as from singin module
import {stylesSignin} from '../SignIn';
import {linksInfo} from '../SignIn';
//import {pageText} from '../SignIn';
import {SignUpFormSimple} from '../SignUp';

import * as ROUTES from "../../constants/routes";
import logomyai from "../../images/my-ai-coach-logo.png";
//import logo from "../../images/rocky-logo-square.png";
import logo from "../../images/rocky-logo-animated.gif";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import { SIGNUPCREATORCODE, MyInstallationOfflineCreatorDataSet, GetUltimimUserSetup} from "../Modules/DefaultCode";

function addHotJar(){
    const script = document.createElement("script");
    script.async = true;
    script.type = "application/javascript"
    script.crossorigin = "anonymous"
    script.src = 'https://app.rocky.ai/hs.js'
    script.onload = () => {
        console.log('HJ added')
    };
    script.onerror = error => {
        console.error(error + 'script load fail!');
    }
    document.body.appendChild(script);
}

class SignUpCreatorPage extends Component {
    constructor(props) {
        super(props);
        var showDetails = undefined;
        var flagBussinessAccount = undefined
        if(window?.location?.href.includes("showdetails=false")){
            showDetails = false;
        }
        if(window?.location?.href.includes("flagBussinessAccount=true")){
            flagBussinessAccount = true;
        }
        var urlCoachCode = CheckCoachCode();
        this.state = { ...INITIAL_STATE, showDetails, flagBussinessAccount};
    }

    componentDidMount() {
        AddAnalytics('screen_view', 'PageSignUpTeams', 'SignUpCoaches');
        //var baseCodeSet = GetDefaultUserSetup(this.props.firebase, MyInstallationOfflineCreatorDataSet, SIGNUPCREATORCODE).then((resultCode) => {
        //    console.log('resultCode inside', SIGNUPCREATORCODE, resultCode)
        //})
        //// This is overwriting the default twincode, and on top adding the special code, nut not overwriting the default code
        LoadAndSaveSignupDefaultCode(this, (this.props.defaultSet || {}), SIGNUPCREATORCODE, 'noStorage').then((newResult) => {
            //console.log('newResult', newResult)
        })
        GetUltimimUserSetup(this.props.firebase, MyInstallationOfflineCreatorDataSet, (this.props.defaultSet || {}), SIGNUPCREATORCODE, this.props.defaultSet?.programCode || "").then((newResult) => {
            if(newResult?.programs?.length > 0){
                this.setState({personalizedDefaultCode: newResult});
                //console.log('GetUltimimUserSetup newResult', newResult)
            }
        })
    }

    pushCreator = () => {
        this.setState({flagBussinessAccount: true});
        var iframeLogin = false;
        if(process?.env?.REACT_APP_DEVICE_PLATFORM==="BROWSER"){
            try{
                if(window.parent !== window.self){
                    //console.log('iframe 1x', window.parent, window.self)
                    iframeLogin = true;
                    window.parent.postMessage({ loggedIn: true });
                    //console.log('iframe 2x', window.parent, window.self)
                    window.parent.postMessage('loggedIn: true');
                    //console.log('iframe 3x', window.parent, window.self)
                    //window.top.location.href = '#/home'
                }
            }
            catch(e){
                console.log('e', e)
            }
        }
        var page = ROUTES.CREATOR_SIGN_UP;
        if(iframeLogin){
            try{
                window.top.location.href = '#'+page+"?showdetails="+this.state.showDetails+"&"+"flagBussinessAccount=true"; 
            }
            catch(e){
            }
        }
        else{
        }
    }

    pushSignin = () => {
        this.setState({flagExistingAccount: true});
        var iframeLogin = false;
        if(process?.env?.REACT_APP_DEVICE_PLATFORM==="BROWSER"){
            try{
                if(window.parent !== window.self){
                    //console.log('iframe 1x', window.parent, window.self)
                    iframeLogin = true;
                    window.parent.postMessage({ loggedIn: true });
                    //console.log('iframe 2x', window.parent, window.self)
                    window.parent.postMessage('loggedIn: true');
                    //console.log('iframe 3x', window.parent, window.self)
                    //window.top.location.href = '#/home'
                }
            }
            catch(e){
                console.log('e', e)
            }
        }
        var page = ROUTES.SIGN_IN;
        if(iframeLogin){
            try{
                page = ROUTES.HOME;
                window.top.location.href = '#'+page+"?showdetails="+this.state.showDetails+"&flagExistingAccount=true";
            }
            catch(e){
                this.props.history.push( {pathname: page})
            }
        }
        else{
            this.props.history.push( {pathname: page})
        }
    }

    render () {
        const { classes, theme } = this.props;
        const { defaultSet } = this.state;
        const hostname = window?.location?.hostname || "";
        var innerWidth = window.innerWidth;
        //console.log('sucp', this.state)
        return (
            <div className={classes.rootFrame}>
                <div className={classes.rootSignupCoach}>
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                    >
                        {defaultSet?.programTitle?.length > 0 && innerWidth >= 1000 && this.state.showDetails!==false
                        &&
                            <Grid item xs style={{ maxWidth: 'calc(100% - 600px)', marginTop: 20}}>
                                {this.renderDefaultProgram(innerWidth, defaultSet)}
                            </Grid>
                        }
                        <Grid item xs style={{ maxWidth: theme.maxWidthDialog }}>
                            <div className={classes.login}>
                                <div className={classes.logoContainer}>
                                    {hostname === "" || hostname?.includes("rocky.ai")
                                        ?   <img src={logo} className={classes.logo} alt="Rocky.ai Logo Chatbot Journaling" />
                                        :   <img src={logomyai} className={classes.logo} alt="My AI Coach powered by Rocky.ai" />
                                    }
                                    {hostname === "" || hostname?.includes("rocky.ai")
                                        ?   <h4>Rocky.ai for Businesses</h4>
                                        :   <h4>my AI Coach for Businesses</h4>
                                    }
                                    <p style={theme.divCenter}>
                                        Sign up here if you are ready to <strong>Create</strong> your custom <strong>AI Coach</strong> and if you want to access the <strong>Creator Panel</strong>.
                                        <br /> <br /> 
                                        Or click <Link to={ROUTES.SIGN_UP} onClick={this.pushSignin}>here</Link> if you want to use Rocky.ai only for yourself!
                                    </p>
                                </div>
                                {this.state.flagBussinessAccount !== true
                                    ?
                                        <Grid item xs={12} style={{ width:'100%', maxWidth: theme.maxWidthDialog }}>
                                            <div>
                                                <div>
                                                    <div className={classes.socialLogins}>
                                                        <Button style={{marginTop: 40, marginBottom: 20}} onClick={this.pushCreator} >
                                                            Create Business Account
                                                        </Button>
                                                    </div>
                                                    <div style={{width: "100%", textAlign: 'center', marginBottom: 60}}>
                                                        <span style={theme.textSupportCenter}>FREE Trial included!</span>
                                                    </div>
                                                    <table width="100%" style={{paddingTop: 20}}>
                                                        <tbody>
                                                            <tr>
                                                                <td><hr /></td>
                                                                <td style={{width:1, padding: '0 10px', whiteSpace: 'nowrap'}}>
                                                                    <span className={classes.socialLoginPrompt}>Already have an account?</span>
                                                                </td>
                                                                <td><hr /></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <div className={classes.socialLogins}>
                                                        <Button
                                                            onClick={this.pushSignin}
                                                            variant='outlined'
                                                            color='secondary'
                                                            className={classes.buttonBottom}
                                                        >
                                                            Log In
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                    :
                                        <Grid item xs={12} style={{ width:'100%', maxWidth: theme.maxWidthDialog }}>
                                            <SignUpFormSimple 
                                                codeRequired= {false}
                                                hideCoachcode= {true}
                                                allowURLCoachcode={true}
                                                showSocial= {true}
                                                coach= {true}
                                                defaultSignUpCode= {this.state.personalizedDefaultCode?.programs?.length > 0 ? undefined : SIGNUPCREATORCODE}
                                                defaultSet= {this.props.defaultSet || {}}
                                                myInstallationOfflineDataSet= {this.state.personalizedDefaultCode?.programs?.length > 0 ? this.state.personalizedDefaultCode : undefined}
                                            />
                                            <div>
                                                <hr />
                                                <p className={classes.socialLoginPrompt}>Already signed up?</p>
                                                <div className={classes.socialLogins}>
                                                    <Button 
                                                        onClick={this.pushSignin}
                                                        className={classes.buttonBottom} 
                                                        variant='outlined'
                                                        color='secondary'
                                                    >
                                                        Log In
                                                    </Button>
                                                    {!(defaultSet?.title?.length > 0) && this.state.showDetails!==false
                                                    &&
                                                        <>
                                                            <br/>
                                                            <Button className={
                                                                classes.buttonBottom} 
                                                                variant='outlined'
                                                                color='secondary'
                                                                onClick={ () => window.open("https://www.rocky.ai/hrd", "_blank")}
                                                            >
                                                                Product Information
                                                            </Button>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </Grid>
                                }
                            </div>
                            {this.state.flagBussinessAccount === true
                            &&
                                <div className={classes.footer}>
                                    {linksInfo(defaultSet)}
                                </div>
                            }
                        </Grid>
                        {defaultSet?.programTitle?.length > 0 && innerWidth < 1000 && this.state.showDetails!==false
                        &&
                            <Grid item xs={12} style={{ maxWidth: theme.maxWidthDialog, marginTop: 20}}>
                                {this.renderDefaultProgram(innerWidth, defaultSet)}
                            </Grid>
                        }
                    </Grid>
                </div>
            </div>
        ); //end return
    }; //end redner

    renderDefaultProgram(innerWidth=0, defaultSet){
        const { classes, theme } = this.props;
        //console.log('theme', classes.innerHTML)
        return(
            <div style={{
                    ...theme.WelcomeLogin, 
                    padding: 20, 
                    marginRight: 10, 
                    //borderTopRightRadius: innerWidth>=1000 ? 0 : theme.borderRadius, 
                    //borderBottomRightRadius: innerWidth>=1000 ? 0 : theme.borderRadius, 
                    width: 'auto', 
                    maxWidth: 'auto',
                    //borderStyle: "solid",
                    //borderWidth: 1,
                    //borderColor: theme.palette.backPaper
                }}
            >
                <div>
                    <h1>
                        {defaultSet.programTitle}
                    </h1>
                    <h2>
                        {defaultSet.programSubTitle}
                    </h2>
                    <div style={{width: '100%', textAlign: 'center'}}>
                        {defaultSet?.programImage?.includes("http")
                            &&
                            <img
                                src={defaultSet.programImage}
                                style={{...theme.logo, width: '100%', maxWidth: 600}} 
                                alt="AI Coaching Chatbot" 
                            />
                        }
                    </div>
                    <div
                        className={classes.innerHTML}
                        onClick = {(event) => {
                            try{
                                const link = event.target.getAttribute('href');
                                if(link !== undefined && link !== null && link !== ""){
                                    event.preventDefault();
                                    window.open(link, "_blank")
                                }
                            }
                            catch(e){
                                console.log('No Link', e)
                            }
                        }}
                        dangerouslySetInnerHTML={{ __html:  DOMPurify.sanitize(defaultSet.programDescription) }}>
                    </div>
                </div>
                <div >
                    &nbsp;
                </div>
                <div style={theme.logoContainer}>
                    {defaultSet.programBrand?.length > 10
                        &&
                        <img
                            onClick={() => {
                                if (defaultSet.programSupportURL?.length>10){ window.open((defaultSet?.programSupportURL ), "_blank")}
                                else{ void(0)}
                            }}
                            src={defaultSet.programBrand}
                            style={theme.logo} 
                            alt="AI Coaching Chatbot" 
                        />
                    }
                    {defaultSet.programCompany?.length > 10
                        &&
                        <div
                            onClick={() => {
                                if (defaultSet.programSupportURL?.length>10){ window.open((defaultSet?.programSupportURL ), "_blank")}
                                else{ void(0)}
                            }}
                        >
                            {defaultSet.programCompany}
                        </div>
                    }
                </div>
            </div>
        )
    }
}; //end class

const INITIAL_STATE = {
    username: "",
    email: "",
    passwordOne: "",
    code: "",
    error: null,
    openCode: false,
};

SignUpCreatorPage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withRouter,
    withStyles(stylesSignin, { withTheme: true }),
    withFirebase)
(SignUpCreatorPage);