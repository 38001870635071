import React, { Component } from "react";
import { compose } from 'recompose';
//import { Link } from "react-router-dom";
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import { AddAnalytics } from '../Modules/AddAnalytics';

import withStyles from "@material-ui/core/styles/withStyles";
// style used same as from singin module
import {stylesSignin} from '../SignIn';

import PropTypes from "prop-types";
import queryString from 'query-string';

import logo from "../../images/rockyai-logo-image-only.png";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";


class SignInAlexaPage extends Component {
    static contextType = AuthUserContext;
    constructor(props) {
        super(props);
        this.state = { 
            ...INITIAL_STATE
        };
        //this.state = { };
    }

    componentDidMount(){
        if (window.location && window.location.search !== undefined && window.location.search!==""){
            //var query = queryString.parse(window.location.search)
            //var urlSearch = window.location.href.slice(window.location.href.indexOf('?'));
            //var query = queryString.parse(urlSearch);
            //this.createAccount(query);
            //console.log("detail loc alexasignin", query)
            //console.log("mount context", this.context)
        }
        AddAnalytics('screen_view', 'PageSignIn', 'SignInAlexa');
    }

    createAccount(query){
        if(query?.scope === "profile:user_id" && query?.client_id?.length > 2 && query?.state?.length > 2 ){
            var requestConnection={ "category" : "alexa"}
            //result = /?code=ANbFWxvinZYBIuIYpzLr&scope=alexa%3A%3Askills%3Aaccount_linking&state=HISlvAC7yOO28nMPgjaNLW46tD82#/
            //client_id is the skill Id of the app
            var alexaClientId = query.client_id;
            //alexaClientId = "amzn1.application-oa2-client.16e21ed0422a4c249818798e26e91ef9"
            requestConnection["skillId"] = alexaClientId;
            requestConnection["storeId"] = alexaClientId;
            requestConnection["status"] = "REQUEST";
            requestConnection["createdAt"] = this.props.firebase.fieldValue.serverTimestamp();
            requestConnection["codeTempAmazon"] = query.state;
            var requestName = 'alexa_' + (new Date().getTime()) ;
            if(this.context?.uid?.length > 3){
                var relatedUserId = this.context.uid;
                requestConnection["userId"] = relatedUserId;
                this.props.firebase.user(relatedUserId).collection("accounts").doc(requestName).set(requestConnection, {merge:true })
                //window.location = "/#/profile"+searchCopy
            }
        }
    }

    confirmToAlexa(confirm=true) {
        if (window.location && window.location.search !== undefined && window.location.search!==""){
            //var query = queryString.parse(window.location.search)
            var urlSearch = window.location.href.slice(window.location.href.indexOf('?'));
            var query = queryString.parse(urlSearch);
            console.log('query alexa link', query)
            //this.createAccount(query);
            if(query?.scope === "profile:user_id"){
                var redirect_uri = ""+query.redirect_uri;
                redirect_uri = redirect_uri.replace('#/alexasignin', '')
                if(confirm){
                    redirect_uri = redirect_uri + "?state=" + query.state;
                    redirect_uri = redirect_uri + "&access_token=" + this.context.uid;
                    redirect_uri = redirect_uri + "&code=" + this.context.uid;
                    //console.log("uri amazon",  query.redirect_uri);
                    //console.log("detail uri", redirect_uri);
                    window.location = redirect_uri;
                }
                else{
                    window.location = redirect_uri;
                }
            }
        }
    }

    render () {
        const { classes, theme } = this.props;
        return (
            <div className={classes.rootFrame}>
                <div >
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={12} style={{ maxWidth: theme.maxWidthDialog }}>
                            <div className={classes.login}>
                                <div className={classes.logoContainer}>
                                    <img src={logo} className={classes.logo} alt="AI Mentor Logo Chatbot Journaling" />
                                    <h4>Connect with Alexa</h4>
                                    <p style={theme.divCenter}>
                                            <span>By choosing <strong>Accept</strong>, you agree to give <strong>Rocky.ai Alexa skill</strong> permission to do the following:</span>
                                            <li>Access your AI Coaching account</li>
                                            <li>Morning mindset or evening reflections</li>
                                            <li>Chose module defined in app for AI chats</li>
                                        <br /> 
                                    </p>
                                </div>
                                <div>
                                    <hr />
                                    <div className={classes.socialLogins}>
                                        <Button  className={classes.buttonBottom}
                                            onClick={() => {this.confirmToAlexa(true)}}
                                        >
                                            ACCEPT
                                        </Button>
                                    </div>
                                    <div className={classes.socialLogins}>
                                        <Button variant="contained" className={classes.buttonBottom}
                                            onClick={() => {this.confirmToAlexa(false)}}
                                        >
                                            REJECT
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        ); //end return
    }; //end redner
}; //end class

const INITIAL_STATE = {
    username: "",
    email: "",
    passwordOne: "",
    code: "",
    error: null,
    openCode: false,
};

SignInAlexaPage.propTypes = {
    classes: PropTypes.object.isRequired
};


export default compose(
    withFirebase,
    withStyles(stylesSignin, { withTheme: true })
)(SignInAlexaPage);
