import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import {linksInfo} from '../SignIn';
import {stylesSignin} from '../SignIn';

import logo from '../../images/rockyai-logo-image-only.png';
//import logoCaption from '../../images/rockyai-logo-caption.png';

// MUI stuff
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import queryString from 'query-string';

class LinkEmailUnsubscribe extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            email: '',
            error: null,
            unsubscribeStatus: 'unsubscribing...',
            uid: '',
            unsubscribeSuccess: false
        };
        this.actionCodeSettings = {
            // URL you want to redirect back to. The domain (www.example.com) for
            // this URL must be whitelisted in the Firebase Console.
            url: 'https://app.rocky.ai/#/emailnotifications?whatfor=emntfda',
            //url: 'http://localhost:3000/emailnotifications?whatfor=emntfda',
            // This must be true for email link sign-in.
            handleCodeInApp: true,
        };
    }

    componentDidMount(){
        this.checkUnsubscribeSettings();
    }

    submitFeedback = () => {
        if(this.state.feedback !== undefined && this.state.feedback.length>2){
            //console.log('this.state', this.state)
            var urlSearch = window.location.href.slice(window.location.href.indexOf('?'));
            var query = queryString.parse(urlSearch)
            const uid = query["witptu"]
            if(uid!== undefined && uid.length>4 ){
                //console.log('this.state', this.state)
                this.props.firebase.dialogues().add({
                    coachId: 'agent',
                    createdAt: this.props.firebase.fieldValue.serverTimestamp(),
                    dialogueType: 'priming',
                    status: false,
                    title: 'Unsubscribe',
                    userId: uid,
                    vote: 'down',
                    feedback: ('Unsubscribe: ' + this.state.feedback)
                })
            }
            //this.checkUnsubscribeSettings();
            this.setState({feedbackResult: "Thanks for the feedback, we appreciate your time and efforts and it will help us to make the service better."})
        }
    }

    checkUnsubscribeSettings() {
        if (window.location.href!== undefined) {
            const url = window.location.href;
            if(url.includes('whatfor=emntfda&witptu=')){
                //var urlSearch = window.location.href.replace('/#/','').replace('#/','');
                var urlSearch = window.location.href.slice(window.location.href.indexOf('?'));
                var query = queryString.parse(urlSearch)
                //console.log( urlSearch);
                //var query = queryString.parse(window.location.href)
                const uid = query["witptu"]
                //console.log('p', uid, query);
                if(uid!==undefined && uid!==""){
                    var errorMessage = new Error('Unsubscribing email address ...'); 
                    this.setState({uid: uid, unsubscribeSuccess: true,  error : errorMessage, feedbackResult: 'We loved working with you, maybe another time? 💕'})
                    this.sendUnsubscribe(uid);
                }
            }
        }
    }

    OLDsignInUserCheckEmailLink() {
        if (this.props.firebase.checkSignInWithEmailLink(window.location.href)) {
            // Additional state parameters can also be passed via URL.
            // This can be used to continue the user's intended action before triggering
            // the sign-in operation.
            // Get the email if available. This should be available if the user completes
            // the flow on the same device where they started it.
            var email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
                // User opened the link on a different device. To prevent session fixation
                // attacks, ask the user to provide the associated email again. For example:
                email = window.prompt('Please provide your email for confirmation');
            }
            if(email){
                this.props.firebase.doSignInWithEmailLink(email, window.location.href)
                .then((result) => {
                    //console.log(result)
                    if('user' in result && 'uid' in result.user && window.location.href.includes('whatfor=emntfda')){
                        var uid = result.user.uid;
                        this.props.firebase.user(uid).update({
                            emailNotifications: 'never'
                        });
                        var errorEmail = new Error('Email notifications deactivated for: ' +email); 
                        this.setState({ error : errorEmail, jobDone: true});
                    }
                    else{
                        var error2 = new Error('Please check your profile settings: ' +email); 
                        this.setState({ error : error2});
                    }
                    // Clear email from storage.
                    // window.localStorage.removeItem('emailForSignIn');
                    // You can access the new user via result.user
                    // Additional user info profile not available via:
                    // result.additionalUserInfo.profile === null
                    // You can check if the user is new or existing:
                    // result.additionalUserInfo.isNewUser
                })
                .catch((error) => {
                    this.setState({ error });
                });
            }
        }
    }
    OLDsignInUserWithEmailLink = () => {
        const { email } = this.state;
        this.props.firebase.doGenerateSignInWithEmailLink(email, this.actionCodeSettings)
        .then(() => {
            // Construct sign-in with email link template, embed the link and
            // send using custom SMTP server.
            //console.log(link)
            var errorEmail = new Error('Email with link was sent to: ' +email); 
            this.setState({ error : errorEmail});
        })
        .catch((error) => {
            this.setState({ error });
        });
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { classes, theme, defaultSet } = this.props;
        const { error } = this.state;

        return (
            <div className={classes.rootFrame}>
                <div className={classes.root}>
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={12} style={{ maxWidth: theme.maxWidthDialog }}>
                            <div className={classes.login}>
                                <Link to={ROUTES.HOME}>
                                    <div className={classes.logoContainer}>
                                    <img 
                                        src={defaultSet?.programLogo?.length > 5 
                                            ?   defaultSet.programLogo
                                            :   logo
                                        }
                                        className={classes.logo} alt="My AI Coach" />
                                    </div>
                                </Link>
                                <h4>Your email notifications</h4>
                                {error && <p className={classes.error}>{error.message}</p>}
                                <div>
                                    <hr />
                                    <p className={classes.socialLoginPrompt}>Access app settings</p>
                                    <div className={classes.socialLogins}>
                                        <Link to={ROUTES.PROFILE}>
                                            <Button className={classes.buttonBottom}>
                                                Profile Settings
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                                {this.state.feedbackResult!==undefined && this.state.unsubscribeSuccess === true
                                    ? <p className={classes.error}>{this.state.feedbackResult}</p>
                                    : <p className={classes.error}>Please wait a moment, we are unsubscribing you....</p>
                                }
                                {(this.state.unsubscribeSuccess === undefined || this.state.unsubscribeSuccess !== false) 
                                    &&
                                    <div>
                                        <hr />
                                        <TextField
                                            name="feedback"
                                            //label="Feedback"
                                            value={this.state.feedback || ""}
                                            onChange={this.onChange}
                                            type="text"
                                            variant="outlined"
                                            placeholder="Feedback"
                                            fullWidth
                                            className={classes.textField}
                                        />
                                        <p className={classes.socialLoginPrompt}>Please help us to improve and share your feedback</p>
                                        <div className={classes.socialLogins}>
                                            <Button
                                                onClick={() => this.submitFeedback()}
                                                className={classes.button}
                                                //disabled={isInvalid}
                                                type="submit"
                                            >
                                                Send Feedback
                                            </Button>
                                        </div>
                                    </div>
                                }    
                                
                                    {/***
                                        <div>
                                            <hr />
                                            <TextField
                                            name="email"
                                            //label="Email"
                                            value={this.state.email}
                                            onChange={this.onChange}
                                            type="text"
                                            variant="outlined"
                                            placeholder="Email"
                                            fullWidth
                                            className={classes.textField}
                                            />
                                            
                                            <p style={{ marginTop: 30, marginBottom:  50}}>
                                            Send email unregister link
                                            <Button
                                                onClick={() => this.onSubmit()}
                                                className={classes.button}
                                                //disabled={isInvalid}
                                                type="submit"
                                            >
                                                Send via email
                                            </Button>
                                            </p>
                                        </div>
                                    */}                        
                            </div>
                            <div className={classes.footer}>
                                {linksInfo(defaultSet)}
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }

    /**
     * @description Formats the HTTP request
     * @param {String} endpointType
     *  Possible values: 'firebase' OR 'rocky'
     * @param {*} options
     */
    sendHttpRequest(options) {
        return fetch(`https://us-central1-rocky-beta-dev.cloudfunctions.net/user_unsubscribe_email`, options);
    }

    /**
     * @description Send message
     * @param {Json} content
     *  Assumes the format:
     *  {
     *    sender: <uid>,
     *    message: '....'
     *  }
     */
    async sendUnsubscribe(uid) {
        //console.log('sendmessage',content);
        var feedback = "";
        if(this.state.feedback !== undefined && this.state.feedback!==""){
            feedback = this.state.feedback;
        }
        let content = {
            action : 'unsubscribeEmail',
            feedback : feedback,
            uid : uid
        };
        try {
            let response = {};
            //console.log(content)
            const options = {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                //mode: 'no-cors',
                body: JSON.stringify(content),
                cache: 'no-cache'
            }
            //console.log(options)
            
            /** */
            response = await this.sendHttpRequest(options);
            let result = response.json()
            .then(data => {
                if(data!== undefined && data.result!== undefined && data.result === "unsubscribed"){
                    //console.log(data)
                    this.setState({email : data.email })
                    var errorMessage = new Error('Successfully unsubscribed from email notifications: ' +data.email); 
                    this.setState({unsubscribeSuccess: true,  error : errorMessage})
                }
                else{
                    var errorMessageFailure = new Error('We could not change the notifications, please go to your app profile settings!'); 
                    this.setState({unsubscribeSuccess: false,  error : errorMessageFailure})
                }
            });
            return result
        } 
        catch (err) {
            console.log('server err',err)
            return null
        }
    }
}

export default LinkEmailUnsubscribe = compose(
    withStyles(stylesSignin, { withTheme: true }),
    withFirebase
)(LinkEmailUnsubscribe);
